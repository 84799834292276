<template>
  <loader-page v-if="loading" />

  <v-container v-else>
    <!-- stepper -->
    <v-stepper
      v-model="step"
      class="transparent"
      color="primary"
      elevation="0"
      alt-labels
    >
      <!-- headers -->
      <v-stepper-header class="elevation-0 px-md-12">
        <v-stepper-step :complete="step > 1" step="1">
          <p class="text-center text-body-1">Método de Pagamento</p>
        </v-stepper-step>

        <v-divider />

        <v-stepper-step :complete="step > 2" step="2">
          <p class="text-center text-body-1">Pagamento</p>
        </v-stepper-step>

        <v-divider />

        <v-stepper-step :complete="step === 3" step="3">
          <p class="text-center text-body-1">Resumo e Conclusão</p>
        </v-stepper-step>
      </v-stepper-header>

      <v-row v-if="jwtDecoded" class="justify-center pb-1">
        <v-col cols="12" sm="10" md="6" lg="5" xl="4">
          <!-- content -->
          <router-view />
        </v-col>

        <v-col cols="12" sm="10" md="5" lg="4" xl="3" class="pl-md-12">
          <!-- resume -->
          <v-card class="pa-6 mb-8" color="foreground">
            <v-card-title class="px-0 pt-0">
              Detalhes do pagamento
            </v-card-title>

            <v-divider class="mb-6 mt-3" />

            <v-list-item class="px-0" dense>
              <v-list-item-action-text class="text-body-1">
                VALOR
              </v-list-item-action-text>

              <v-list-item-title class="text-right text-h6">
                R$ {{ moneyMask(jwtDecoded.transaction.convertedValue) }}
              </v-list-item-title>
            </v-list-item>
          </v-card>

          <!-- flip card -->
          <FlipCard v-if="$route.name === 'Payment Card'" class="mb-4" />

          <!-- safety -->
          <v-list-item class="mb-8" three-line>
            <v-list-item-icon class="mr-4 pt-2">
              <v-icon color="success accent-3" size="32"
                >mdi-shield-check</v-icon
              >
            </v-list-item-icon>

            <v-list-item-content class="primary_text--text">
              <v-list-item-title class="text-body-1">
                Pagamento Seguro
              </v-list-item-title>

              <v-list-item-subtitle class="secondary_text--text">
                Garantimos a segurança e privacidade de seus dados financeiros
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- logo -->
          <v-row class="justify-center" no-gutters>
            <v-col cols="3" sm="5" md="5" lg="6">
              <v-img :src="require('@/assets/logo.png')" contain />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- invalid jwt -->
      <v-row v-else class="justify-center align-center text-center py-4">
        <v-col>
          <p class="text-h5 font-weight-regular mb-6">
            Transação <span class="font-weight-bold">inválida</span> ou <br />
            <span class="font-weight-bold">não encontrada</span>.
          </p>

          <v-btn
            v-if="jwtDecoded && jwtDecoded.transaction.redirectURL"
            class="mb-6"
            color="primary"
            :href="jwtDecoded.transaction.redirectURL"
            large
            depressed
          >
            Clique aqui para retornar
          </v-btn>

          <v-row class="justify-center">
            <v-col cols="6" sm="3" lg="2">
              <v-img :src="require('@/assets/logo.png')" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { decode, getTransaction } from "@/services/checkout";
import { moneyMask } from "@/utils";
import FlipCard from "@/components/checkout/FlipCard";

export default {
  data() {
    return {
      loading: true,
      step: 1,
    };
  },

  components: {
    FlipCard,
  },

  created() {
    this.getData();
  },

  beforeMount() {
    this.handleStep();

    // listen continue transaction
    this.$root.$on("continue-transaction", (data) => {
      this.continueTransaction(data);
    });
  },

  beforeDestroy() {
    this.setTransactionPerformed(false);
  },

  computed: {
    ...mapState(["jwtDecoded"]),
  },

  watch: {
    ["$route"]() {
      this.handleStep();
    },
  },

  methods: {
    ...mapActions([
      "setJwtDecoded",
      "setCurrent",
      "setTransactionPerformed",
      "setAwaitingMethods",
      "setLockedMethods",
    ]),

    // get and commit decoded jwt
    async getData() {
      try {
        let query = this.$route.query.p || null;

        if (!query) return;

        await decode(query).then((res) => {
          const decode = res.body;
          this.setJwtDecoded(decode);

          this.handleTransactions(decode);

          if (decode.transaction.method) {
            this.setTransactionPerformed(true);
            this.continueTransaction(decode.transaction.ref);
          }
        });
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },

    // flow of continue transaction
    async continueTransaction(value) {
      try {
        this.loading = true;

        const payload = {
          ref: value,
          document: this.jwtDecoded.customer.document,
        };

        await getTransaction(payload).then((res) => {
          this.setCurrent(res.body); // commit on storage

          const method = res.body.transaction.paymentMethod; // current method

          // check if the method requires 2 steps, dont have receipt and status has awaiting
          if (
            method !== "CARD" &&
            !res.body.data.urlReceipt &&
            res.body.transaction.transactionStatusId === 1
          ) {
            this.navigation(`/checkout/payment/${method.toLowerCase()}`);
          } else {
            this.navigation(`/checkout/summary`);
          }
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // handel pendent and blocked transactions
    handleTransactions(decode) {
      const customer = decode.customer;
      const currentValue = decode.transaction.convertedValue;

      let awaiting = [];

      if (decode.awaitingTransactions) {
        decode.awaitingTransactions.map((e) => {
          if (e.convertedValue === currentValue) {
            awaiting.push(e.paymentMethod);
          }
        });
      }

      const blocked = {
        // CARD: !customer.allowed && (!customer.isNewUser || (customer.isNewUser && currentValue > 200)),
        CARD: !customer.allowed,
        BOLETO: true, //decode.transaction.convertedValue < 100,
        TRANSFER: decode.banks.length < 1,
      };

      this.setAwaitingMethods(awaiting);
      this.setLockedMethods(blocked);
    },

    // set current step
    handleStep() {
      const param = this.$route.name;

      if (param === "Checkout") this.step = 1;
      else if (param === "Summary") this.step = 3;
      else this.step = 2;
    },

    // router push
    navigation(path) {
      this.$router.push({ path: path });
    },

    moneyMask,
  },
};
</script>

<style lang="scss" scoped></style>
