<template>
  <v-row class="justify-center" no-gutters>
    <vue-flip v-model="flip" :height="height" :width="width">
      <!-- front of card -->
      <template v-slot:front>
        <v-card
          class="rounded-lg no-select"
          color="foreground"
          :height="height"
          :width="width"
        >
          <section class="gradient fill-height">
            <v-layout class="px-4 py-2" column fill-height>
              <v-flex xs4 row no-gutters>
                <v-spacer />

                <v-img
                  :src="
                    require(`@/assets/flags/${card.brand || 'DEFAULT'}.png`)
                  "
                  max-width="64"
                  contain
                />
              </v-flex>

              <v-flex class="text-h5" align-center row no-gutters>
                <span class="card-font white--text">
                  {{ card.number || pattern.number }}
                </span>
              </v-flex>

              <v-flex xs3>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-subtitle
                      class="text-caption grey--text text--lighten-4"
                    >
                      TITULAR DO CARTÃO
                    </v-list-item-subtitle>
                    <v-list-item-title class="card-font white--text">
                      {{ card.holder || pattern.holder }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-subtitle
                      class="text-caption grey--text text--lighten-4"
                    >
                      VAL
                    </v-list-item-subtitle>

                    <v-list-item-title class="card-font white--text">
                      {{ card.expDate || pattern.expDate }}
                    </v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
              </v-flex>
            </v-layout>
          </section>
        </v-card>
      </template>

      <!-- back of card -->
      <template v-slot:back>
        <v-card
          class="rounded-lg no-select"
          color="foreground"
          :height="height"
          :width="width"
        >
          <section class="gradient fill-height pt-8">
            <v-sheet class="mb-2" color="#151515" width="100%" height="48" />

            <v-row class="px-4" no-gutters>
              <v-spacer />

              <span class="card-font white--text">
                {{ card.cvv || pattern.cvv }}
              </span>
            </v-row>
          </section>
        </v-card>
      </template>
    </vue-flip>
  </v-row>
</template>

<script>
import VueFlip from "vue-flip";

export default {
  data() {
    return {
      flip: false,
      card: {},
      pattern: {
        number: "#### #### #### ####",
        holder: "########",
        cvv: "###",
        expDate: "##/##",
      },
      width: "320px",
      height: "192px",
    };
  },

  components: {
    "vue-flip": VueFlip,
  },

  mounted() {
    this.$root.$on("input-card", (data) => {
      this.card = data;
    });

    this.$root.$on("flip-card", (data) => {
      this.flip = data;
    });
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap");

.card-font {
  font-family: "Dosis", sans-serif;
  text-shadow: .75px .75px #202020;
}

.gradient {
  background: linear-gradient(180deg, #ffb218 0%, #ff6600 100%);
}
</style>
